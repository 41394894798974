import React from 'react';
import illustrations2 from '../../../images/pricing/hexowatch_pricing_ilustration2.png';
import './styles.scss';

const PremiumCredits = () => {
  return (
    <>
      <div className="row pricing-premium-credits my-2 mx-auto pl-3 pr-3">
        <div className="col-12 p-0">
          <h2>Premium credit add-on</h2>
        </div>
        {/* <div className="col-12 col-md-6 premiums">
          <div>
            Get discounted access to residential proxies right inside Hexowatch
            using our cost effective premium credit system ( 0.12 credits / MB)
            starting from just $9.99/m.{" "}
          </div>
          <div className="text_bold mt-2">
            To celebrate the launch of Hexowatch version 3.0 and our new
            residential proxy option we created a special annual deal where you
            can save up to 33% off premium credit prices.
          </div>
          <div className="d-block justify-content-center">
            <div className="d-block flex-wrap mt-2">
              <div className="d-flex align-items-center mr-4 pr-3 pr-lg-0">
                <div className="circle-point"></div>
                <div className="py-2">
                  <div>
                    <span className="text_bold">100</span> credits monthly
                    allowance
                  </div>
                  <div>
                    {" "}
                    <span className="text_line_through">$119</span>{" "}
                    <span className="text_bold">$99</span> per year{" "}
                    <span className="text_bold">(Save 15%)</span>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center mr-4 ">
                <div className="circle-point"></div>
                <div className="py-2">
                  <div>
                    <span className="text_bold">150</span> credits monthly
                    allowance
                  </div>
                  <div>
                    {" "}
                    <span className="text_line_through">$179</span>{" "}
                    <span className="text_bold">$139</span> per year{" "}
                    <span className="text_bold">(Save 20%)</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-block flex-wrap mt-2">
              <div className="d-flex align-items-center mr-4">
                <div className="circle-point"></div>
                <div className="py-2">
                  <div>
                    <span className="text_bold">250</span> credits monthly
                    allowance
                  </div>
                  <div>
                    {" "}
                    <span className="text_line_through">$299</span>{" "}
                    <span className="text_bold">$225</span> per year{" "}
                    <span className="text_bold">(Save 25%)</span>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <div className="circle-point"></div>
                <div className="py-2">
                  <div>
                    <span className="text_bold">500</span> credits monthly
                    allowance
                  </div>
                  <div>
                    {" "}
                    <span className="text_line_through">$599</span>{" "}
                    <span className="text_bold">$399</span> per year{" "}
                    <span className="text_bold">(Save 33%)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-12 col-md-6 p-0">
          <div className="premium-machine-translation">
            Tap into our optional residential proxy network with our premium credit add-on.
          </div>

          <div className="d-block d-sm-flex my-3 align-items-center">
            <div className="mr-4">
              <div>Starting from just</div>
              <div className="mt-2" style={{fontSize: '24px'}}>
                <sup>$</sup>9<sup>99</sup>/<span className="f_14">monthly</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 text-center">
            <img src={illustrations2} alt="illustrations" />
          </div>
          <div className="col-12 col-md-6 text-left proxy p-1">
            <p className="title">Residential GEO proxy</p>
            <p style={{fontSize: '14px'}}>
              <span>0.12</span> premium credits per MB
            </p>
            <p style={{fontSize: '14px'}}>
              <span>105</span> premium credits provides <span>875</span> MB of traffic bandwidth.
            </p>
          </div>
        </div>
        {/* <div className="col-12">
          <div className="row">
            <div className="col-12 col-md-6 text-center p-1">
              <img src={illustrations} alt="illustrations" />
            </div>
            <div className="col-12 col-md-6 text-left proxy p-1">
              <p className="title">Residential proxy</p>
              <p>
                <span>0.1</span> premium credits per MB
              </p>
              <p>
                <span>105</span> premium credits provides <span>1,050</span> MB of traffic bandwidth.
              </p>
            </div>
            <div className="col-12 col-md-6 text-left proxy p-1 mt-auto">
              <p className="title">Residential geo proxy</p>
              <p>
                <span>0.12</span> premium credits per MB
              </p>
              <p>
                <span>105</span> premium credits provides <span>875</span> MB of traffic bandwidth.
              </p>
            </div>
            <div className="col-12 col-md-6 text-center p-1 ">
              <img src={illustrations2} alt="illustrations" />
            </div>
          </div>
            </div>*/}
      </div>
    </>
  );
};

export default PremiumCredits;
