import React, { FC } from "react";
import { ISwitch } from "../types";
import "./styles.scss";

const Switch: FC<ISwitch> = ({ text1, text2, active, onChange, className }) => {
  return (
    <div className={`d-flex switch-wrapper ${className ? className : ""}`}>
      <div
        className={`${active ? "active" : ""}`}
        onClick={() => onChange(text1)}
      >
        {text1}
      </div>
      <div
        className={`${!active ? "active" : ""}`}
        onClick={() => onChange(text2)}
      >
        {text2}
      </div>
    </div>
  );
};

export default Switch;
